import { CTAJotaPro, HamburguerMenu, HeaderBase } from '.'
import EditorialsComponent from './EditorialsComponent'
import { HeaderContainer } from './Header.styled'
import { useDispatch, useSelector } from 'react-redux'
import { FC, useEffect, useState } from 'react'
import { fetchAuthedUser } from '../../../api/ducks/auth'
import { AppDispatch } from '../../../api/types/auth'
import { StoreType } from '../../../api/types/store'
import useScrollPosition from '@App/libs/hooks/useScroll'
import { headerInterface } from '@App/libs/interfaces/global.interface'
import { SCROLL_LIMIT_NORMAL } from '@App/libs/utils/constants/global'
import BannerTopComponent from './BannerTop.component'
import AdminToolbar from '../admin-toolbar'
import { useRouter } from 'next/router'

/**
 * Header Component
 * @return {React.JSX.Element}
 */
const HeaderComponent: FC<headerInterface> = ({
    wpMenu,
    ctaPRO,
    showAcessibility,
    signupLink,
    pageTributos = false,
    bannerTop = null
}): React.JSX.Element => {
    const dispatch = useDispatch<AppDispatch>()
    const scrollPosition = useScrollPosition()
    const [hasEditorAccess, setHasEditorAccess] = useState<boolean>(false)
    const router = useRouter()

    const { auth, ui } = useSelector((state: StoreType) => state)

    useEffect(() => {
        if (router.query.p) {
            const page = Number(router.query.p)
            if (!isNaN(page)) {
                window.location.href = '/redirect?p=' + page
            }
        }
    }, [router.query.p])

    useEffect(() => {
        dispatch(fetchAuthedUser())
    }, [dispatch])

    useEffect(() => {
        const user: any = auth.user?.getUserBO()
        setHasEditorAccess(
            user?.email.includes('@jota.info') ||
                user?.role?.some(
                    (role: string) =>
                        role === 'administrator' || role === 'editor'
                )
        )
    }, [auth.user])

    return (
        <div data-testid='header-container'>
            <AdminToolbar hasEditorAccess={hasEditorAccess} />

            <HeaderContainer
                scroll={scrollPosition > SCROLL_LIMIT_NORMAL}
                hasEditorAccess={hasEditorAccess}
            >
                <CTAJotaPro
                    {...{ auth }}
                    showArticle={ctaPRO.enabled}
                    link={ctaPRO.link}
                    txtMobile={ctaPRO.textMobile}
                    txtNormal={ctaPRO.textLarge}
                />

                <HeaderBase
                    {...{ auth, showAcessibility, pageTributos }}
                    isHeader={true}
                />

                {!pageTributos && (
                    <EditorialsComponent
                        {...{ auth, wpMenu, signupLink, pageTributos }}
                    />
                )}

                {ui.sidebar.isOpen && <HamburguerMenu {...{ wpMenu }} />}
                {bannerTop && (
                    <BannerTopComponent
                        {...bannerTop}
                        scroll={scrollPosition > SCROLL_LIMIT_NORMAL}
                    />
                )}
            </HeaderContainer>
        </div>
    )
}

export default HeaderComponent
